<template>

	<div id="experience"
		ref="page"
		:class="{
			'_with-icons': hasIcons,
			'_internal': isInternal,
			'_rtl': rtl,
			'_landscape': isLandscape,
			'_istoobig': isTooBig
		}">
		<p class="landing" v-if="experiences.length === 0">
			Start adding talking points to generate your experience.
		</p>
		<div class="experiences" v-else>
			<div class="slides" ref="slides">
				<div
					v-for="(slide, index) in slides"
					:key="index"
					:id="`slide-${index + 1}`"
					:ref="`slide-${index + 1}`"
					:class="{ '_selected': index + 1 === selectedSlide && markSelectedSlide, '_hidden': index + 1 !== selectedSlide && hideUnselectedSlides }"
					@click="$emit('click:slide', index + 1)">
					<div class="content">
						<header :ref="`header-${index + 1}`">
							<div class="text">
								<h2>{{ slide.header }}</h2>
								<h3>{{ slide.subHeader }}</h3>
								<p>{{ slide.description }}</p>
							</div>
							<div class="map" :class="mapClassType(mapClass)">
								<i v-bind:class="[`icon`, `icon-maps-${mapClass}`]" aria-hidden="true"></i>
							</div>
						</header>
						<div class="background">

							<Draggable
								:list="slide.experiences"
								:move="handleMove"
								handle=".draggable"
								@end="handleDragEnd($event, index)"
								class="results"
								:class="{ 'edit-mode': isEditMode }"
								:ref="`results-${index + 1}`"
							>
								<div
									v-for="(experience, experienceIndex) in slide.experiences"
									:class="{ 'draggable': isEditMode }"
									class="result"
									:key="experienceIndex"
									:id="`point-${index + 1}-${experienceIndex + 1}`"
									:ref="index === slides.length - 1 && experienceIndex === slide.experiences.length - 1 && 'last-result'"
									@contextmenu="rightClick($event, `point-${index + 1}-${experienceIndex + 1}`)"
								>
									<span class="percentage">
										<span class="icon" :class="{ 'has-icon': !!(experience.surchargedIcon || experience.icon)}" >
											<i :class=" experience.surchargedIcon ? `${iconPrefix}${experience.surchargedIcon}` : `${iconPrefix}${experience.icon}`" aria-hidden="true"></i>

											<ul class="icons" >
												<li v-for="(icon, index) in icons" :key="index" @click="changeIcon(experience.id, icon)">
													<i :class="`${iconPrefix}${icon}`" aria-hidden="true"></i>
												</li>
											</ul>
										</span>
										{{ experience.percentage }}
									</span>
									<p>{{ experience.title }}</p>
								</div>

								<div
									v-if="isEditMode && index === slides.length - 1 && slide.experiences.length < nbPerSlide"
									class="result result__preview d-flex justify-center"
									:style="{ 'min-height': `${lastResultHeight}px`, 'max-height': `${lastResultHeight}px` }"
									@click="$emit('goBack')">
									<v-icon class="add-icon align-self-center">
										add
									</v-icon>
									<div class="d-flex select-more">
										<v-icon> arrow_back_ios_new </v-icon>
										<p class="ma-0 align-self-center">Select more Talking Points</p>
									</div>
								</div>
							</Draggable>

						</div>
					</div>
					<footer :ref="`footer-${index + 1}`">
						<p>{{ texts.methodology }}</p>
						<img src="@/assets/footer/Meta_lockup_primary_RGB.svg" alt="Meta logo" />
					</footer>
					<div v-if="showSlideNumber" class="slide-number">{{ index + 1 }}</div>
				</div>
			</div>
		</div>

		<div class="xMark"></div>
		<div class="yMark"></div>

	</div>

</template>

<script>

import Draggable from 'vuedraggable';

const { mapMutations } = require('vuex');

export default {

	components: {
		Draggable
	},

	props: {
		isEditMode: {
			type: Boolean,
			default: false
		},
		texts: {
			type: Object,
			required: true
		},
		experiences: {
			type: Array,
			required: true
		},
		mapClass: {
			type: String,
			required: true
		},
		hasIcons: {
			type: Boolean,
			default: false
		},
		isInternal: {
			type: Boolean,
			default: false
		},
		nbPerSlide: {
			type: Number,
			required: true
		},
		selectedSlide: {
			type: Number
		},
		rtl: {
			type: Boolean,
			default: false
		},
		isLandscape: {
			type: Boolean,
			default: false
		},
		value: {
			type: Number,
			default: 0
		},
		icons: {
			type: Array
		},
		showSlideNumber: {
			type: Boolean,
			default: false
		},
		markSelectedSlide: {
			type: Boolean,
			default: false
		},
		hideUnselectedSlides: {
			type: Boolean,
			default: false
		}
	},

	data () {
		return {
			resizeTimeoutId: null,
			lastResultHeight: null,
			iconPrefix: 'icon-talkingPoints-',
			futureIndex: null,
			movingIndex: null,
			currentHoverIndex: null,
			oldRelated: null,
			slides: [],
			isTooBig: false
		};
	},

	computed: {
		nbSlides: {
			get () {
				return this.value;
			},
			set (value) {
				this.$emit('input', value);
			}
		},
		pageHeight () {
			return this.$refs.page.clientHeight;
		},
		slideWidth () {
			const slide = this.$refs[`slide-${this.selectedSlide}`][0];
			const style = getComputedStyle(slide);
			return slide.offsetWidth + parseInt(style.marginLeft) + parseInt(style.marginRight);
		}

	},

	methods: {
		...mapMutations('talkingPoints', [
			'swapExperiences'
		]),
		setLastResultHeight () {
			const lastResult = this.$refs['last-result'];
			if (Array.isArray(lastResult) && lastResult.length) {
				this.lastResultHeight = lastResult[0].offsetHeight;
			}
		},
		headerHeight (index) {
			const header = this.$refs[`header-${index + 1}`][0];
			const style = getComputedStyle(header);
			return header.offsetHeight + parseInt(style.marginTop) + parseInt(style.marginBottom);
		},
		footerHeight (index) {
			const footer = this.$refs[`footer-${index + 1}`][0];
			const style = getComputedStyle(footer);
			return footer.offsetHeight + parseInt(style.marginTop) + parseInt(style.marginBottom);
		},
		resultsHeight (index) {
			const results = this.$refs[`results-${index + 1}`][0].$el;
			const style = getComputedStyle(results);
			return results.offsetHeight + parseInt(style.marginTop) + parseInt(style.marginBottom);
		},
		mapClassType (className) {
			let ret = '';
			if (className.substr(0, 6) === 'globe_') {
				ret = '_globe';
			} else if (className.substr(0, 10) === 'cityscape_') {
				ret = '_cityscape';
			} else if (className === 'map_Global-Map') {
				ret = '_worldmap';
			} else if (className.substr(0, 4) === 'map_') {
				ret = '_map';
			}

			return ret;
		},
		rightClick: function (event, id) {
			this.$emit('rightClick', id);
			event.preventDefault();
		},
		handleDragEnd (e, slideIndex) {
			if (this.currentHoverIndex === null) return;

			let add = 0;
			for (let index = 0; index < slideIndex; index++) {
				add += this.slides[index].experiences.length;
			}

			const order = {
				newIndex: this.futureIndex + add,
				oldIndex: this.movingIndex + add,
				swap: true
			};

			this.swapExperiences(order);

			this.oldRelated && this.oldRelated.classList.remove('_hover');
			this.movingIndex = this.futureIndex = this.currentHoverIndex = this.oldRelated = null;
		},
		handleMove (e) {
			const { index, futureIndex } = e.draggedContext;
			this.movingIndex = index;
			this.futureIndex = futureIndex;
			if (this.currentHoverIndex !== futureIndex) {
				this.oldRelated && this.oldRelated.classList.remove('_hover');
				e.related.classList.add('_hover');
				this.currentHoverIndex = futureIndex;
				this.oldRelated = e.related;
			}
			return false; // disable sort
		},
		changeIcon (experienceId, icon) {
			this.$emit('changeIcon', experienceId, icon);
		},
		async createSlides () {
			this.isTooBig = false;
			const experiences = [...this.experiences];
			this.slides = [];
			const slides = [];
			if (!experiences.length) {
				this.nbSlides = 0;
				return;
			}
			while (experiences.length) {
				const slide = await this.createSlide(experiences, this.nbPerSlide);
				if (!slide) return;
				slides.push(slide);
				experiences.splice(0, slide.experiences.length);
			}
			this.slides = slides;
			this.nbSlides = this.slides.length;
			this.$nextTick(() => {
				if (this.isLandscape) {
					const headerHeight = this.headerHeight(0);
					const footerHeight = this.footerHeight(0);
					const contentsHeight = this.pageHeight - footerHeight;
					if (headerHeight > contentsHeight) { this.isTooBig = true; }
				}
			});
		},
		async createSlide (experiences, nbPerSlide) {
			if (nbPerSlide <= 0) return;
			const slide = {
				header: this.texts.header,
				subHeader: this.texts.subHeader,
				description: this.texts.description,
				experiences: [],
				nbPerSlide: nbPerSlide
			};
			this.slides.push(slide);
			let max = 0;
			for (let index = 0; index < experiences.length && max < nbPerSlide; index++) {
				const experience = experiences[index];
				slide.experiences.push(experience);
				max++;
			}
			let fit = false;
			await this.$nextTick(() => {
				const headerHeight = this.isLandscape ? 0 : this.headerHeight(0);
				const footerHeight = this.footerHeight(0);
				const contentsHeight = this.pageHeight - headerHeight - footerHeight;
				const resultsHeight = this.resultsHeight(0);
				fit = contentsHeight >= resultsHeight;
			});
			this.slides = [];
			if (fit) return slide;
			return this.createSlide(experiences, nbPerSlide - 1);
		},
		setLastResultHeightOnResize () {
			clearTimeout(this.resizeTimeoutId);
			this.resizeTimeoutId = setTimeout(this.setLastResultHeight, 200);
		}
	},
	async mounted () {
		await this.createSlides();
		await this.$nextTick();
		this.setLastResultHeight();

		window.addEventListener('resize', this.setLastResultHeightOnResize);
	},

	destroyed () {
		window.removeEventListener('resize', this.setLastResultHeightOnResize);
	},

	watch: {
		async experiences () {
			await this.createSlides();
			await this.$nextTick();
			this.setLastResultHeight();
		},
		async isInternal () {
			await this.createSlides();
			await this.$nextTick();
			this.setLastResultHeight();
		},
		async selectedSlide () {
			await this.$nextTick();
			this.setLastResultHeight();
		}
	}

};

</script>

<style lang="scss" scoped>

@import "@/scss/_colors.scss";

* { box-sizing: border-box; }

.experiences, .slides { height: 100% !important; }

.draggable {
  cursor: grab;
}

.landing {
	color: #D0D0D0;
	padding: 1em;
}

.experiences {
	width: 100%;
	position: relative;
}

.results {
  &.edit-mode {

    .result {
      border: 1px dashed;

      .icon {
        &:hover {
          .icons {
            display: flex;
            z-index: 101;
          }
        }
      }

      & > .percentage .icon.has-icon {
        border: 1px dashed;
      }

      &.result__preview {
        cursor: pointer;

        .add-icon {
          font-size: 2em !important;
          font-weight: 500;
        }

        .select-more {
          opacity: 0;
          height: 0;
          width: 0;

          font-size: 0.6em !important;
          font-weight: 500;
        }

        &:hover {

          .select-more {
            opacity: 1;
            height: auto;
            width: auto;

            i {
              font-size: 2em !important;
            }
          }

          .add-icon {
            display: none;
          }
        }

      }
    }

  }

}

.slides {
	display: flex;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	&::-webkit-scrollbar {
		display: none;
	}
	> div {
		flex-shrink: 0;
		width: 100%;
		transition: transform 0.5s;
		display: flex;
		flex-direction: column;
    &._selected {
      box-shadow: 0px 0px 4px 1px black;
    }
    &._hidden {
      display: none;
    }
		> .content {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			> header {
				padding: .8em .8em 0 1em;
				display: flex;
				margin-bottom: 1em;
				> .text {
					width: 90%;
					margin-right: 0.7em;
					> h2 {
						font-size: 1em;
						font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
						font-weight: 500;
						line-height: 1.2em;
						margin-bottom: .1em;
					}
					> h3 {
						font-size: .38em;
						line-height: 1.3em;
						margin-bottom: .1em;
						font-family: 'Optimistic Text', 'Noto Sans Khmer', sans-serif;
						font-weight: 900;
					}
					> p {
						font-size: .35em;
						line-height: 1.45em;
						font-family: 'Optimistic Text', 'Noto Sans Khmer', sans-serif;
						font-weight: 400;
						margin: 0;
					}
				}
				> .map {
					width: 40%;
					display: flex;
					align-items: center;
					> i {
						font-size: 6em;
						margin-top: -.25em;
					}
					&._globe > i { font-size: 5em; margin-top: -.17em; }
					&._worldmap > i { font-size: 7em; margin-top: -.57em; }
					&._cityscape > i { font-size: 7em; margin-top: -.57em; }
				}
			}
			> .background {
				display: flex;
				flex-grow: 1;
				margin-left: 1em;
				padding: 0;
				> .results {
					display: flex;
					flex-wrap: wrap;
					padding: .4em .5em .5em;
					height: fit-content;
					width: 100%;
					> .result {
            margin: .2em;
						padding: .3em;
            flex: 0 0 calc(50% - 2*.2em);

						&._hover { background-color: $light-gray-03; }
						> .percentage {
							font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
							font-weight: 500;
							font-size: 1.69em;
							line-height: 1.1em;
							margin-bottom: .1em;
							display: inline-block;
							padding-right: .3em;
							.icon {
								vertical-align: bottom;
								line-height: .7955em;
								position: relative;
								display: none;
								margin-right: 0.07em;
								font-size: .94em;
								img {
									height: 2em;
								}
							}
						}
						> p {
							font-size: .44em;
							line-height: 1.1em;
							font-family: 'Optimistic Text', 'Noto Sans Khmer', sans-serif;
							font-weight: 400;
							margin: .12em 0 0 0;
						}
					}
				}
			}
		}
		> footer {
			margin: .5em 0 .5em 1em;
			display: flex;
			align-items: center;
			> p {
				font-size: .25em;
				width: 78%;
				line-height: 1em;
				margin: 0;
			}
			> img {
				width: 16%;
        margin-left: 0.4em;
			}
		}
    .slide-number {
      position: absolute;
      top: 0;
      left: -1em;
      width: 1em;
      text-align: left;
      font-size: 2em;
      color: black;
      background-color: transparent;
    }
	}
}


//
// RTL mode
//

#experience._rtl {
	direction: rtl;
	.slides > div > .content > .background { margin-left: 0; margin-right: 1em; }
	.slides > div > footer { margin: .5em 1em .5em 0; }
}


//
// With icons mode
//

#experience._with-icons {
	.slides > div > .content > .background > .results > .result > .percentage > .icon { display: inline-flex; }
}


//
// Internal mode
//

#experience._internal {
	.slides > div > .content > header > .map, .slides > div > .content > header > .text > p { display: none; }
	.slides > div > .content > .background > .results { flex-direction: column; padding-right: .4em; }
	.slides > div > .content > .background > .results > .result { width: 100%; padding: .2em .3em; margin: .2em; }
	.slides > div > .content > .background > .results > .result > .percentage { float: left; margin-bottom: 0; font-size: 1.4em; }
	.slides > div > .content > .background > .results > .result > .percentage > .icon { display: none; }
	.slides > div > .content > .background > .results > .result > p { padding-left: 1.04em; }
}


//
// Landscape mode
//

#experience._landscape {
	.landing { font-size: 16px; }
	.slides {
		> div {
			padding: 2.5em 0 1.5em;
			> .content {
				flex-direction: row;
				> header {
					flex-direction: column-reverse;
					width: 30.625%;
					padding: 0;
					margin: 0;
					margin-left: 3.5em;
					> .text {
						width: auto;
						margin: 0;
						margin-bottom: auto;
						> h2 {
							font-size: 3.9375em;
							line-height: 1em;
						}
						> h3 {
							font-size: 1.375em;
							line-height: 1.273em;
							margin: .5em 0 1em 0;
						}
						> p {
							font-size: 1.25em;
							line-height: 1.2em;
						}
					}
					> .map {
						position: relative;
						height: 9em;
						margin-bottom: 1.5em;
						display: block; // overload portrait mode beaviour
						> i {
							height: 100%;
							display: inline-block;
							position: relative;
							top: -1.9375em;
							left: -1.9375em;
							font-size: 1em;
							&:before {
								font-size: 13em;
							}
						}
						&._globe > i {
							top: -.8em;
							&:before { font-size: 11em; }
						}
						&._worldmap > i {
							left: 0;
							top: -7em;
							&:before { font-size: 18em; }
						}
						&._cityscape > i {
							left: 0;
							top: -7em;
							&:before { font-size: 18em; }
						}
					}
				}
				> .background {
					margin-left: 5em;
					width: 69.375em;
					> .results {
						padding: 1em;
						> .result {
							padding: 1em;
							> .percentage {
								font-size: 5em;
								line-height: 1em;
								.icon {
									font-size: 0.88em;
								}
							}
							> p {
								font-size: 1.25em;
								line-height: 1.3em;
							}
						}
					}
				}
			}
			> footer {
				margin: 1.9375em 0 0 3.5em;
				position: relative;
				> p {
					font-size: .625em;
				}
				> img {
					width: auto;
					height: 6em;
					position: absolute;
					right: 1.5em;
					top: -2em;
				}
			}
		}
	}
	&._istoobig {
		.experiences {
			.slides {
				header {
					.text {
						h2 {
							font-size: 2.5em;
						}
					}
				}
			}
		}
	}
}


//
// Landscape mode + rtl mode
//

#experience._landscape._rtl {
	.slides {
		> div {
			> .content {
				> header {
					margin-left: 0;
					margin-right: 3.5em;
					> .map {
						i {
							left: auto;
							right: -2.9375em;
						}
					}
				}
				> .background {
					margin-left: 0;
					margin-right: 5em;
				}
			}
			> footer {
				margin-left: 0;
				margin-right: 3.5em;
				> img {
					right: auto;
					left: 1.5em;
				}
			}
		}
	}
}


//
// Landscape mode + internal mode
//

#experience._landscape._internal {
	.slides {
		> div {
			> .content {
				> header {
					width: 24.16666666666667%;
					margin-left: 3em;
				}
				> .background {
					width: 70.83333333333333%;
					margin-left: 3em;
					> .results {
						> .result {
							padding-bottom: 0;
							> .percentage {
								font-size: 4em;
								line-height: 1.25em;
							}
							> p {
								font-size: 1.125em;
								line-height: 1.225em;
								margin-top: .7em;
							}
						}
					}
				}
			}
		}
	}
}


//
// Landscape mode + internal mode + RTL mode
//

#experience._landscape._internal._rtl {
	.slides {
		> div {
			> .content {
				> header {
					margin-left: 0;
					margin-right: 3em;
				}
				> .background {
					margin-left: 0;
					margin-right: 3em;
					> .results {
						> .result {
							> .percentage {
								float: right;
								padding-right: 0;
								padding-left: .3em;
							}
						}
					}
				}
			}
		}
	}
}


//
// icons tooltip
//


ul.icons {
	display: none;
  width: 330px;
  left: -330px;
	flex-wrap: wrap;
	position: absolute;
	overflow: hidden;
	&._displayed { display: flex }
	li {
		flex: 0 0 16.6666666667%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 30px;
		height: 55px;
		i { display: flex; }
		cursor: pointer;
	}
}


//
// default colors
//

.slides > div {
	color: $meta-gray;
	> .content > header > .map > i { color: $light-gray-03; }
	> .content > .background { background-color: white; }
  .percentage .icon i { color: $meta-blue; }
  .add-icon { color: $blue-03; }
  .select-more { color: $meta-blue; }
  .select-more i {color: $meta-blue; }
  > footer > p { color: #556167; }
	> footer > svg {  fill: $green-06; }
}

$icons-border: 1px solid #3F4C53;
ul.icons {
	background-color: $gray-100;
	li {
		&:hover { background-color: $light-gray-02; }
    border-right: $icons-border;
    border-bottom: $icons-border;
    &:nth-child(6n+1) {
			border-left: $icons-border;
		}
		&:nth-child(-n+6) {
			border-top: $icons-border;
		}
	}
}

</style>
